import _ from 'lodash';
import {
  ComponentDefinition,
  ComponentRef,
  EditorSDK,
  Layout,
  PageRef,
  ResponsiveLayout,
} from '@wix/platform-editor-sdk';
import * as controllers from './controllers';
import * as menus from './menus';
import * as tpa from './tpa';
import * as constants from '../constants';
import { SOSP_COMP_RESPONSIVE_COMP_DEF } from '../constants/layouts/sosp/responsive-sosp-layouts';
import { LOGIN_COMP_RESPONSIVE_APP_WIDGET_DEF } from '../constants/layouts/login-bar/responsive-login-bar-layouts';
import {
  MEMBERS_MENU_RESPONSIVE_APP_WIDGET,
  MEMBERS_MENU_RESPONSIVE_APP_WIDGET_RTL,
} from '../constants/layouts/menus/responsive-menu-layouts';
import { getSiteLocale, isRtlLocale } from '../../utils/locale';
import { log } from '../../utils/monitoring';
import { isADIHorizontalLayoutEnabled } from '../../utils/experiments';
import { getIsADI, getIsResponsiveEditor, getStaticsBaseUrl } from '../services/applicationState';
import i18next from '../../i18n';
import {
  LOGIN_COMP_APP_WIDGET_DEF,
  LOGIN_COMP_APP_WIDGET_DEF_ECOM,
} from '../constants/layouts/login-bar/classic-login-bar-layouts';
import { getClassicEditorMembersMenuDef } from '../constants/layouts/menus/classic-menu-layouts';

const { APP_TOKEN } = constants;

async function addProfileWidget(editorSDK: EditorSDK, sospContainer: ComponentRef) {
  const isResponsiveEditor = getIsResponsiveEditor();
  const allowHorizontalInADI = await isADIHorizontalLayoutEnabled();
  const isHorizontalLayout = !getIsADI() || allowHorizontalInADI;
  const { appDefinitionId } = constants.PROFILE_WIDGET_APP;
  const isAlreadyInstalled = await tpa.isApplicationInstalled({ editorSDK, appDefinitionId });

  // Remove existing components for unexpected cases when they are already installed in the site
  if (isAlreadyInstalled) {
    const appData = await tpa.getDataByAppDefId({ editorSDK, appDefinitionId });
    if (appData?.applicationId) {
      const components = await tpa.getAllCompsByApplicationId({ editorSDK, applicationId: appData.applicationId });
      if (components && components.length > 0) {
        const componentsRefs = await getComponentsRefsByIds({ editorSDK, ids: components.map((c) => c.id) });
        await deleteComponents({ editorSDK, componentsRefs });
      }
    } else {
      log('Invalid getDataByAppDefId', { extra: { appDefinitionId, source: 'addProfileWidget' } });
    }
  }

  await editorSDK.tpa.add.application(APP_TOKEN, {
    appDefinitionId,
    ...(isHorizontalLayout ? constants.PW_HORIZONTAL_LAYOUT : constants.PW_VERTICAL_LAYOUT),
    // @ts-expect-error
    containerRef: sospContainer,
    cancelSave: true,
  });

  // For some reason initially defined width from dev center does not change if we provide another value, so we resize after installation
  // This can most likely be removed when dev center width value changes from 250 to 980
  try {
    if (isHorizontalLayout) {
      const { applicationId } = await editorSDK.tpa.app.getDataByAppDefId(
        APP_TOKEN,
        constants.PROFILE_WIDGET_APP.appDefinitionId,
      );
      const profileWidgetComponents = await editorSDK.tpa.app.getAllCompsByApplicationId(APP_TOKEN, applicationId);
      const installedComponentRef = await editorSDK.components.getById(APP_TOKEN, {
        id: profileWidgetComponents[0].id,
      });
      await editorSDK.components.layout.update(APP_TOKEN, {
        componentRef: installedComponentRef,
        layout: constants.PW_HORIZONTAL_LAYOUT,
      });
    }
  } catch (e) {
    log('An error occured when resizing PW to horizontal layout, the MA installation will most likely look broken', {
      tags: { error: (e as Error).toString() },
    });
  }

  if (isResponsiveEditor) {
    const { applicationId } = await editorSDK.tpa.app.getDataByAppDefId(
      APP_TOKEN,
      constants.PROFILE_WIDGET_APP.appDefinitionId,
    );
    const components = await editorSDK.tpa.app.getAllCompsByApplicationId(APP_TOKEN, applicationId);
    const compId = components[0].id;
    const componentRef = await editorSDK.components.getById(APP_TOKEN, { id: compId });
    await editorSDK.document.responsiveLayout.update(APP_TOKEN, {
      componentRef,
      responsiveLayout: constants.PW_RESPONSIVE_LAYOUT as unknown as ResponsiveLayout,
    });
  }
}

async function addLoginButton(
  editorSDK: EditorSDK,
  controllerRef: ComponentRef,
  headerRef: ComponentRef,
  isStoresInstallation: boolean = true,
) {
  const isResponsiveEditor = getIsResponsiveEditor();
  const shouldInstallAppWidgets = !getIsADI();
  const compDef = isStoresInstallation ? constants.LOGIN_COMP_DEF_ECOM : constants.LOGIN_COMP_DEF;
  const defaultLanguagePromise = editorSDK.info.getLanguage(APP_TOKEN);
  const headerLayout = await editorSDK.components.layout.get(APP_TOKEN, { componentRef: headerRef });
  compDef.layout.y = (headerLayout.height - compDef.layout.height) / 2;
  compDef.data.language = await defaultLanguagePromise;

  let loginRef;
  let finalControllerRef = controllerRef;

  if (shouldInstallAppWidgets) {
    const appWidgetCompDef = (isResponsiveEditor
      ? LOGIN_COMP_RESPONSIVE_APP_WIDGET_DEF
      : isStoresInstallation
      ? LOGIN_COMP_APP_WIDGET_DEF_ECOM
      : LOGIN_COMP_APP_WIDGET_DEF) as unknown as ComponentDefinition;

    finalControllerRef = await editorSDK.components.add(APP_TOKEN, {
      pageRef: headerRef,
      componentDefinition: appWidgetCompDef,
      // @ts-expect-error
      showAddToHeaderPanel: false,
    });

    loginRef = (await editorSDK.document.components.getChildren(APP_TOKEN, { componentRef: finalControllerRef }))[0];
  } else {
    loginRef = await editorSDK.components.add(APP_TOKEN, {
      pageRef: headerRef,
      componentDefinition: (isResponsiveEditor
        ? compDef
        : { ...compDef, layoutResponsive: undefined }) as unknown as ComponentDefinition,
      // @ts-expect-error
      showAddToHeaderPanel: false,
    });
  }

  await connectLoginButton(editorSDK, loginRef, !shouldInstallAppWidgets);
}

export async function connectLoginButton(
  editorSDK: EditorSDK,
  loginRef: ComponentRef,
  shouldConnectToController: boolean,
) {
  const menusIds = menus.getMenuIds();
  // To do: It should be possible to have this data in the definiton itself, so less handling would happen here
  const dataToUpdate = {
    menuItemsRef: {
      menuRef: '#' + menusIds.login,
      type: 'CustomMenuDataRef',
    },
    iconItemsRef: {
      menuRef: '#' + menusIds.icons,
      type: 'CustomMenuDataRef',
    },
  };

  await editorSDK.components.data.update(APP_TOKEN, { componentRef: loginRef, data: dataToUpdate });

  if (shouldConnectToController) {
    const globalController = await controllers.getController(editorSDK);
    await controllers.connectToController({
      editorSDK,
      controllerRef: globalController,
      connectToRef: loginRef,
      role: 'members_login',
    });
  }
}

async function addSubPagesMenu(
  editorSDK: EditorSDK,
  menuId: string,
  sospContainer: ComponentRef,
  controllerRef: ComponentRef,
  isHorizontal = false,
  pwHeight?: number,
) {
  const isResponsiveEditor = getIsResponsiveEditor();
  const isADI = getIsADI();
  const allowHorizontalInADI = await isADIHorizontalLayoutEnabled();
  const shouldInstallHorizontalMenu = (!getIsADI() || allowHorizontalInADI) && isHorizontal;
  const isHorizontalLayout = isResponsiveEditor || shouldInstallHorizontalMenu;
  const shouldInstallAppWidgets = !isADI;
  const locale = await getSiteLocale(editorSDK);
  const shouldInstallRtlLocale = isRtlLocale(locale);
  const i18n = await i18next(getStaticsBaseUrl(), locale);

  if (shouldInstallAppWidgets) {
    let appWidgetDef;
    if (isResponsiveEditor) {
      appWidgetDef = shouldInstallRtlLocale
        ? MEMBERS_MENU_RESPONSIVE_APP_WIDGET_RTL
        : MEMBERS_MENU_RESPONSIVE_APP_WIDGET;
      appWidgetDef.components[0].props.moreButtonLabel = i18n.t('Members_Subpages_Horizontal_Menu');
    } else {
      appWidgetDef = getClassicEditorMembersMenuDef({ isHorizontal, isRtl: shouldInstallRtlLocale });
      appWidgetDef.components[0].props.moreButtonLabel = i18n.t('Members_Subpages_Horizontal_Menu');
    }

    const appWidgetRef = await editorSDK.components.add(APP_TOKEN, {
      pageRef: sospContainer,
      componentDefinition: appWidgetDef,
      // @ts-expect-error
      showAddToHeaderPanel: false,
    });
    const menuRef = (await editorSDK.document.components.getChildren(APP_TOKEN, { componentRef: appWidgetRef }))[0];
    return connectMenu(editorSDK, menuRef, menuId);
  }

  let compDef;
  if (isHorizontalLayout) {
    const menuDefRTL = {
      ...constants.MENU_COMP_DEF_HORIZONTAL_RTL,
      layout: {
        ...constants.MENU_COMP_DEF_HORIZONTAL_RTL.layout,
        y: pwHeight || constants.MENU_COMP_DEF_HORIZONTAL_RTL.layout.y,
      },
    };
    const menuDef = {
      ...constants.MENU_COMP_DEF_HORIZONTAL,
      layout: {
        ...constants.MENU_COMP_DEF_HORIZONTAL.layout,
        y: pwHeight || constants.MENU_COMP_DEF_HORIZONTAL.layout.y,
      },
    };

    compDef = shouldInstallRtlLocale ? menuDefRTL : menuDef;
    compDef.props.moreButtonLabel = i18n.t('Members_Subpages_Horizontal_Menu');
  } else {
    compDef = shouldInstallRtlLocale ? constants.MENU_COMP_DEF_RTL : constants.MENU_COMP_DEF;
  }

  const menuRef = await editorSDK.components.add(APP_TOKEN, {
    pageRef: sospContainer,
    componentDefinition: compDef,
    // @ts-expect-error
    showAddToHeaderPanel: false,
  });

  await Promise.all([
    connectControllerToMenu(editorSDK, menuRef, controllerRef),
    connectMenu(editorSDK, menuRef, menuId),
  ]);
}

async function connectControllerToMenu(editorSDK: EditorSDK, menuRef: ComponentRef, controllerRef: ComponentRef) {
  // TODO use connectSubPagesMenu function, it's not working now because there are no connected components at that moment in getMenuIds
  await controllers.connectToController({
    editorSDK,
    controllerRef,
    connectToRef: menuRef,
    role: 'members_menu',
  });
}

async function connectMenu(editorSDK: EditorSDK, menuRef: ComponentRef, menuId: string) {
  await editorSDK.menu.connect(APP_TOKEN, { menuCompPointer: menuRef, menuId });
}

async function connectSubPagesMenu(editorSDK: EditorSDK, menuRef: ComponentRef) {
  const menusIds = menus.getMenuIds();
  const globalController = await controllers.getController(editorSDK);

  await Promise.all([
    connectControllerToMenu(editorSDK, menuRef, globalController),
    connectMenu(editorSDK, menuRef, menusIds.members),
  ]);
}

async function createSospContainer(editorSDK: EditorSDK, headerRef: ComponentRef, masterRef: PageRef) {
  const isResponsiveEditor = getIsResponsiveEditor();
  const allowHorizontalInADI = await isADIHorizontalLayoutEnabled();
  const isHorizontalLayout = !getIsADI() || allowHorizontalInADI;
  const sospContainer = await getSOSPContainerRef(editorSDK);

  if (sospContainer) {
    try {
      log('SOSP container already existing during the installation');
      const sospChildren = await editorSDK.components.getChildren(APP_TOKEN, { componentRef: sospContainer });
      await Promise.all(sospChildren.map((componentRef) => editorSDK.components.remove(APP_TOKEN, { componentRef })));
      await removeSospContainer(editorSDK);
    } catch (e) {
      log('Can not remove members SOSP container');
      throw e;
    }
  }

  if (isResponsiveEditor) {
    const responsiveContainerDef = _.cloneDeep(SOSP_COMP_RESPONSIVE_COMP_DEF);
    const headerLayout = await editorSDK.components.layout.get(APP_TOKEN, { componentRef: headerRef });
    responsiveContainerDef.layout.y += headerLayout.height;
    return editorSDK.components.add(APP_TOKEN, {
      pageRef: masterRef,
      componentDefinition: responsiveContainerDef as unknown as ComponentDefinition,
      // @ts-expect-error
      showAddToHeaderPanel: false,
      customId: constants.SOSP_CONTAINER_CUSTOM_ID,
    });
  }

  const containerDef = _.cloneDeep(isHorizontalLayout ? constants.SOSP_CONTAINER_HORIZONTAL : constants.SOSP_CONTAINER);
  const headerLayout = await editorSDK.components.layout.get(APP_TOKEN, { componentRef: headerRef });
  containerDef.layout.y += headerLayout.height;

  return editorSDK.components.add(APP_TOKEN, {
    pageRef: masterRef,
    // @ts-expect-error
    componentDefinition: containerDef,
    showAddToHeaderPanel: false,
    customId: constants.SOSP_CONTAINER_CUSTOM_ID,
  });
}

function shouldHandleComponentAddedToStage(
  compData: { type: 'LoginSocialBar' | 'MenuDataRef' | 'CustomMenuDataRef'; menuRef?: string } | null,
) {
  if (!compData) {
    return false;
  }

  switch (compData.type) {
    case 'LoginSocialBar':
      return true;
    case 'CustomMenuDataRef':
    case 'MenuDataRef':
      return compData.menuRef === '#MEMBERS_SUB_MENU';
    default:
      return false;
  }
}

async function handleCompAddedToStage(editorSDK: EditorSDK, compRef: ComponentRef) {
  // TODO decide component to connect according to comp type after WEED-3513 is resolved and not by compData
  const isAppController =
    compRef.id !== constants.CONTROLLER_COMP_CUSTOM_ID &&
    ((await editorSDK.components.data.get(APP_TOKEN, { componentRef: compRef })) as any)?.type === 'AppController';
  let componentRef = compRef;

  if (isAppController) {
    const controllerConnections = await editorSDK.controllers.getControllerConnections('', { controllerRef: compRef });
    componentRef = controllerConnections[0]?.componentRef;

    // We see controller connections are not always there, but it is not clear when this happens
    if (!componentRef) {
      return;
    }
  }

  const compData: any = await editorSDK.components.data.get(APP_TOKEN, { componentRef });
  const locale = await getSiteLocale(editorSDK);

  if (!shouldHandleComponentAddedToStage(compData)) {
    return;
  }

  await editorSDK.components.properties.update(APP_TOKEN, {
    componentRef,
    props: { itemsAlignment: isRtlLocale(locale) ? 'right' : 'left' },
  });

  if (compData.type === 'LoginSocialBar') {
    await connectLoginButton(editorSDK, componentRef, !isAppController);
  } else if ((compData.type === 'MenuDataRef' || compData.type === 'CustomMenuDataRef') && !isAppController) {
    await connectSubPagesMenu(editorSDK, componentRef);
  }
}

async function removeSospContainer(editorSDK: EditorSDK) {
  const sospRef = await getSOSPContainerRef(editorSDK);
  if (sospRef) {
    await editorSDK.components.remove(APP_TOKEN, { componentRef: sospRef });
  }
}

async function getSOSPContainerRef(editorSDK: EditorSDK) {
  const sospRef = await editorSDK.components.getById(APP_TOKEN, { id: constants.SOSP_CONTAINER_CUSTOM_ID });

  if (sospRef) {
    return sospRef;
  }

  const sospRefFallback = await editorSDK.components.getById(APP_TOKEN, {
    id: `${constants.SOSP_CONTAINER_CUSTOM_ID}_1`,
  });
  return sospRefFallback;
}

async function getComponentsRefsByIds({ editorSDK, ids }: { editorSDK: EditorSDK; ids: string[] }) {
  const compRefGetter = (id) => editorSDK.components.getById(APP_TOKEN, { id });
  return Promise.all(ids.map(compRefGetter));
}

async function deleteComponents({
  editorSDK,
  componentsRefs,
}: {
  editorSDK: EditorSDK;
  componentsRefs: ComponentRef[];
}) {
  return Promise.all(componentsRefs.map((componentRef) => editorSDK.components.remove(APP_TOKEN, { componentRef })));
}

function getComponentLayout({ editorSDK, componentRef }: { editorSDK: EditorSDK; componentRef: ComponentRef }) {
  return editorSDK.components.layout.get('', { componentRef });
}

function updateComponentResponsiveLayout({
  editorSDK,
  componentRef,
  responsiveLayout,
}: {
  editorSDK: EditorSDK;
  componentRef: ComponentRef;
  responsiveLayout: ResponsiveLayout;
}) {
  return editorSDK.document.responsiveLayout.update(APP_TOKEN, { componentRef, responsiveLayout });
}

function getComponentResponsiveLayout({
  editorSDK,
  componentRef,
}: {
  editorSDK: EditorSDK;
  componentRef: ComponentRef;
}) {
  return editorSDK.document.responsiveLayout.get(APP_TOKEN, { componentRef });
}

function updateComponentLayout({
  editorSDK,
  componentRef,
  layout,
}: {
  editorSDK: EditorSDK;
  componentRef: ComponentRef;
  layout: Partial<Layout>;
}) {
  return editorSDK.document.components.layout.update('', { componentRef, layout });
}

function removeComponent({ editorSDK, componentRef }: { editorSDK: EditorSDK; componentRef: ComponentRef }) {
  return editorSDK.document.components.remove('', { componentRef });
}

// https://jira.wixpress.com/browse/EP-2554
// Explicitly deleting mobile components because platform has this issue
async function removeMobileComponent({
  editorSDK,
  componentRef,
}: {
  editorSDK: EditorSDK;
  componentRef: ComponentRef;
}) {
  try {
    const mobileRef = { ...componentRef, type: 'MOBILE' } as ComponentRef;
    const [mobileComp] = await editorSDK.components.get('', { componentRefs: mobileRef });
    if (mobileComp) {
      await editorSDK.document.components.remove('', { componentRef: { ...componentRef, type: 'MOBILE' } });
    }
  } catch (e) {
    log('Mobile component delete failure: ', e);
  }
}

function getComponentChildren({ editorSDK, componentRef }: { editorSDK: EditorSDK; componentRef: ComponentRef }) {
  return editorSDK.document.components.getChildren('', { componentRef });
}

async function getAllComponentChildren({
  editorSDK,
  parentComponentRef,
}: {
  editorSDK: EditorSDK;
  parentComponentRef: ComponentRef;
}) {
  const childrenRefs = await editorSDK.document.components.getChildren(APP_TOKEN, { componentRef: parentComponentRef });
  const descendantsRefs = await Promise.all(
    childrenRefs.map((ref) => getAllComponentChildren({ editorSDK, parentComponentRef: ref })),
  );

  return _.flatten(childrenRefs.concat(descendantsRefs)) as ComponentRef[];
}

function getComponentData({ editorSDK, componentRef }: { editorSDK: EditorSDK; componentRef: ComponentRef }) {
  return editorSDK.document.components.data.get('', { componentRef });
}

async function getSOSPProfileCardComponentRef({ editorSDK }: { editorSDK: EditorSDK }) {
  const sospContainerRef = await getSOSPContainerRef(editorSDK);
  const sospChildren = await getComponentChildren({ editorSDK, componentRef: sospContainerRef });
  const sospChildrenDatas = await Promise.all(
    sospChildren.map((componentRef) => getComponentData({ editorSDK, componentRef })),
  );

  const pwData = sospChildrenDatas.find(
    (data: any) => data.appDefinitionId === constants.PROFILE_WIDGET_APP.appDefinitionId,
  );
  const pwComponentIndex = sospChildrenDatas.indexOf(pwData!);
  return sospChildren[pwComponentIndex];
}

async function getMenuInSOSPCompRef({
  editorSDK,
  sospContainerRef,
}: {
  editorSDK: EditorSDK;
  sospContainerRef: ComponentRef;
}) {
  const sospChildren = await getComponentChildren({ editorSDK, componentRef: sospContainerRef });
  const sospChildrenDatas = await Promise.all(
    sospChildren.map((componentRef) => getComponentData({ editorSDK, componentRef })),
  );
  const menuComponentData = sospChildrenDatas.find(
    (data: any) => data.menuRef === `#${constants.MENU_IDS.SUB_MENU_ID}`,
  );
  const menuComponentIndex = sospChildrenDatas.indexOf(menuComponentData!);
  const menuComponentRef = sospChildren[menuComponentIndex];
  return menuComponentRef;
}

async function fixSOSPHeightForVerticalLayout({
  editorSDK,
  pwComponentRef,
  sospContainerRef,
  menuRef,
}: {
  editorSDK: EditorSDK;
  pwComponentRef?: ComponentRef;
  sospContainerRef?: ComponentRef;
  menuRef?: ComponentRef;
}) {
  const populatedPwCompRef = pwComponentRef || (await getSOSPProfileCardComponentRef({ editorSDK }));
  const populatedSospRef = sospContainerRef || (await getSOSPContainerRef(editorSDK));
  const populatedMenuRef = menuRef || (await getMenuInSOSPCompRef({ editorSDK, sospContainerRef: populatedSospRef }));

  if (!populatedMenuRef || !populatedSospRef || !populatedPwCompRef) {
    return;
  }

  const pwLayout = await getComponentLayout({ editorSDK, componentRef: populatedPwCompRef });
  const menuLayout = await getComponentLayout({ editorSDK, componentRef: populatedMenuRef });
  const menuY = pwLayout.y + pwLayout.height + constants.PW_SIDEBAR_SOSP_BOTTOM_MARGIN;
  const sospHeight =
    pwLayout.height +
    menuLayout.height +
    constants.PW_SIDEBAR_SOSP_TOP_MARGIN +
    2 * constants.PW_SIDEBAR_SOSP_BOTTOM_MARGIN;

  await updateComponentLayout({ editorSDK, componentRef: populatedSospRef, layout: { height: sospHeight } });
  await updateComponentLayout({ editorSDK, componentRef: populatedMenuRef, layout: { y: menuY } });
}

function getById({ editorSDK, id }: { editorSDK: EditorSDK; id: string }) {
  return editorSDK.document.components.getById('', { id });
}

function updateFullStyle({
  editorSDK,
  componentRef,
  style,
}: {
  editorSDK: EditorSDK;
  componentRef: ComponentRef;
  style: Parameters<EditorSDK['components']['style']['updateFull']>[1];
}) {
  return editorSDK.document.components.style.updateFull('', { componentRef, style });
}

function updateProperties({ editorSDK, pageRef, props }: { editorSDK: EditorSDK; pageRef: PageRef; props: any }) {
  return editorSDK.components.properties.update('', {
    componentRef: { id: pageRef.id, type: pageRef.type },
    props,
  });
}

async function getComponentType({ editorSDK, componentRef }: { editorSDK: EditorSDK; componentRef: ComponentRef }) {
  return editorSDK.components.getType(APP_TOKEN, { componentRef });
}

async function removeComponentsByAppDefIds(editorSDK: EditorSDK, appDefIds: string[]) {
  const allComponents = await editorSDK.components.getAllComponents(APP_TOKEN);
  for (const componentRef of allComponents) {
    const componentData: any = await getComponentData({ editorSDK, componentRef });
    if (!componentData) {
      continue;
    }

    if (appDefIds.indexOf(componentData.appDefinitionId) !== -1) {
      await removeComponent({ editorSDK, componentRef });
    }
  }
}

async function getHeaderComponents(editorSDK: EditorSDK): Promise<ComponentRef[]> {
  const header = await editorSDK.siteSegments.getHeader(APP_TOKEN);
  return getAllComponentChildren({ editorSDK, parentComponentRef: header });
}

async function isLoginBarComponentAdded(editorSDK: EditorSDK) {
  const headerChildren = await getHeaderComponents(editorSDK);
  const childrenTypesPromises = headerChildren.map((childRef) =>
    getComponentType({ editorSDK, componentRef: childRef }),
  );
  const childrenTypes = await Promise.all(childrenTypesPromises);
  const hasLoginComponent = childrenTypes.includes('wysiwyg.viewer.components.LoginSocialBar');
  return hasLoginComponent;
}

function registerToComponentAddedToStageEvent(editorSDK: EditorSDK) {
  return editorSDK.document.application.registerToCustomEvents(APP_TOKEN, {
    eventTypes: ['componentAddedToStage'],
  });
}

function getComponentPage(editorSDK: EditorSDK, componentRef: ComponentRef) {
  return editorSDK.components.getPage(APP_TOKEN, { componentRef });
}

export {
  addLoginButton,
  addProfileWidget,
  addSubPagesMenu,
  createSospContainer,
  handleCompAddedToStage,
  removeSospContainer,
  getSOSPContainerRef,
  removeComponent,
  updateComponentLayout,
  getComponentLayout,
  getComponentChildren,
  getAllComponentChildren,
  getComponentData,
  getMenuInSOSPCompRef,
  getSOSPProfileCardComponentRef,
  fixSOSPHeightForVerticalLayout,
  getById,
  updateFullStyle,
  updateProperties,
  getComponentType,
  removeComponentsByAppDefIds,
  isLoginBarComponentAdded,
  updateComponentResponsiveLayout,
  getComponentResponsiveLayout,
  registerToComponentAddedToStageEvent,
  removeMobileComponent,
  getComponentPage,
};
