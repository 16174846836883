import { ComponentRef, EditorSDK } from '@wix/platform-editor-sdk';

import { LOGIN_BAR_CONTROLLER_TYPE } from '../../../constants/layouts/login-bar/classic-login-bar-layouts';
import { getSiteLocale, isRtlLocale } from '../../../../utils/locale';
import { APP_TOKEN } from '../../../constants';
import { connectLoginButton } from '../../../wrappers/components';

const updateLoginBarItemsAlignment = async (editorSDK: EditorSDK, loginBarRef: ComponentRef) => {
  const locale = await getSiteLocale(editorSDK);

  return editorSDK.components.properties.update(APP_TOKEN, {
    componentRef: loginBarRef,
    props: { itemsAlignment: isRtlLocale(locale) ? 'right' : 'left' },
  });
};

export const handleLoginBarAdded = async (editorSDK: EditorSDK, eventPayload?: any) => {
  if (!eventPayload?.componentRef) {
    return;
  }

  const [controllerConnection] = await editorSDK.controllers.getControllerConnections('', {
    controllerRef: eventPayload.componentRef,
  });

  if (controllerConnection?.connection.role !== LOGIN_BAR_CONTROLLER_TYPE) {
    return;
  }

  const loginBarRef = controllerConnection.componentRef;

  return Promise.all([
    updateLoginBarItemsAlignment(editorSDK, loginBarRef),
    connectLoginButton(editorSDK, loginBarRef, false),
  ]);
};
