import { AppActionClicked as AppActionClickedEvent, EventType } from '@wix/platform-editor-sdk';

import { ReferralInfo } from '../../../../types/bi';
import { openRemovePagePanel, openUninstallPanel } from '../../../wrappers/platformPanels';
import { openGeneralSettingsPanel, openMemberPrivacySettingsDashboard } from '../../../wrappers/panels';
import { MembersAreaEvent, MembersAreaOnEventFn } from '../../types';
import { publishSettingsForMembersAreaApps } from '../../../services/members-area';
import { navigateToMembersAreaPage } from '../services/navigation';
import { handleAppManagerEvent } from './apps-manager';
import { handleAddTabs } from './add-tabs';
import { handleLoginBarAdded } from '../services/login-bar';

export const onEvent: MembersAreaOnEventFn = async (event, editorSDK) => {
  const { eventType, eventPayload } = event;

  switch (eventType) {
    // Platform Events
    case EventType.appActionClicked:
      return handleAppManagerEvent(editorSDK, event as AppActionClickedEvent);
    // TODO: Handle with custom pages
    case EventType.pageDeleted:
      console.warn(`[MAV2] OnEvent.${eventType} handler is not implemented`);
      break;
    case EventType.widgetAdded:
      return handleLoginBarAdded(editorSDK, eventPayload);
    case EventType.siteWasPublished:
      return publishSettingsForMembersAreaApps(editorSDK);
    // Members Area Events
    case MembersAreaEvent.CreateBlankPage:
    case MembersAreaEvent.ManagePages:
      return handleAddTabs(editorSDK, eventPayload);
    // TODO: Handle with custom pages
    case MembersAreaEvent.RemovePage:
      return openRemovePagePanel(editorSDK, eventPayload.pageRef);
    case MembersAreaEvent.Uninstall:
      return openUninstallPanel(editorSDK);
    case MembersAreaEvent.GeneralSettings:
      await navigateToMembersAreaPage(editorSDK);
      return openGeneralSettingsPanel({
        editorSDK,
        eventPayload: { ...eventPayload, referralInfo: ReferralInfo.ActionsMenu },
      });
    case MembersAreaEvent.MemberPrivacyDashboard:
      return openMemberPrivacySettingsDashboard(editorSDK);
  }
};
